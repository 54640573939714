import React from 'react';
import { Container, Space } from '../../../GlobalStyles.jsx';
import Highlight from '../../../components/Highlight';
import { Card } from '@etiquette-ui/misc';
import Images from './components/Images';
import { MainTitle, Wrap, Row } from './styles';

import useI18n from '../../../i18n/useI18n';

const Advantages = () => {

    const { translateObject } = useI18n();

    const highlightTitle = {
        en: 'Many',
        es: 'Diferentes'
    }

    const title = {
        en: 'advantages',
        es: 'ventajas'
    }
    
    return (
        <Wrap>
            <Card>
                <Row>
                    <Container dark center>
                        <MainTitle data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-duration='1000'>
                            <Highlight text={translateObject(highlightTitle)} />
                            &nbsp;{translateObject(title)}
                        </MainTitle>
                        <Images />
                    </Container>   
                </Row>
            </Card>
            <Space size='80' />
        </Wrap>
    )
};

export default Advantages;