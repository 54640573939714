import React from "react";
import Seo from "../components/seo";
import Layout from "../containers/Layout";
import HeroSection from "../sections/Home/Hero";
import PowerfulSection from "../sections/Home/Powerful";
import Advantages from '../sections/Home/Advantages';
import DifferentTask from '../sections/Home/DifferentTask';
import Products from '../sections/Home/Products';
import Pricing from '../sections/Home/Pricing';
import Faqs from "../sections/Home/Faqs";
import { Section } from '../GlobalStyles.jsx';
import { BG_DARK, BG } from '@etiquette-ui/colors';
import '../globalStyles.css';

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Section color={BG}>
      <HeroSection />
      <PowerfulSection />
      <Advantages />
    </Section>

    <Section color={BG_DARK}>
      <DifferentTask />
      <Products />
    </Section>

    <Section color='#fff' id='price'>
      <Pricing />
    </Section>

    <Section color={BG_DARK} id='faq'>
      <Faqs />
    </Section>
  </Layout >
)

export default IndexPage;
