import styled from 'styled-components';
import { PRIMARY } from '@etiquette-ui/colors';
import { Link } from 'gatsby';
import { device, minDevice } from '../../../../Theme';

const Info = styled.p`
    font-size: 16px;
    letter-spacing: 0px;
    max-width: 352px;

    @media ${device.tablet} { 
        font-size: 14px;
    }
`;
const Title = styled.p`
    font-size: 32px;
    font-weight: 600;
    max-width: 512px;
    margin: 15px 0px;
`;
const AppName = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    @media ${minDevice.tablet} {
        margin-bottom: 50px;
    }
`;
const AppTitle = styled.div`
    font-size: 24px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    width: 50px;
    margin-left: 15px;

    @media ${device.tablet} { 
        font-size: 20px;
    }
`;
const Image = styled.div`
    width: 65px;
    height: 65px;
`;
const LinkContainer = styled.div`
    display: grid;
    grid-template-columns: auto 20px;
    grid-column-gap: 3px;
    align-items: center;
    justify-content: center;
    height: 19px;
    width: fit-content;
    cursor: pointer;
`;
const LearnMore = styled(Link)`
    width: 100%;
    color: ${PRIMARY};
    font-size: 16px;
    letter-spacing: 0px;
    font-weight: 500;
    text-decoration: none;

    @media ${device.tablet} {
        font-size: 14px;
    }
`;
const ImageContainer = styled.div`
    display: block;
    height: 720px;
    margin-left: 45%;

    &.center {
        margin-left: 55%;
    }

    @media ${device.tablet} {
        display: none;
    }
`;
const ContainerFluid = styled.div`
    max-width: 100%;
    position: relative;
`;
const MainDescription = styled.div`
    
    top: 0px;
    left: 0px;
    right: 0px;
    pointer-events: none;
    z-index: 2;

    @media (min-width: 769px) {
        position: absolute;
        margin-top: 60px;
    }
    
`;
const Body = styled.div`
    width: 41%;
    pointer-events: all;
    margin-left: 8%;
    @media ${device.tablet} {
        width: 100%;
        margin-left: 0px;
    }
`;
export {
    AppName,
    Title,
    AppTitle,
    Image,
    LinkContainer,
    LearnMore,
    Info,
    ImageContainer,
    ContainerFluid,
    MainDescription,
    Body
};