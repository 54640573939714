import React from 'react';
import Img from 'gatsby-image';
import Item from './Item';
import { info } from '../data';
import { useStaticQuery, graphql } from "gatsby";
import {Grid} from '../styles';

const Images = () => {
  const data = useStaticQuery(dataQuery);

  return (
    <Grid>
      {data.allFile.edges.map((image, key) => (
        <Item title={info[key].title} text={info[key].description}>
          <Img
            key={key}
            fluid={image.node.childrenImageSharp[0].fluid}
            alt={image.node.base}
          />
        </Item>
      ))
      }
    </Grid>
  );
};

export default Images;

export const dataQuery = graphql`
query Advantages {
  allFile(
    filter: {
      extension: {regex: "/(png)/"}, 
      name: {in: ["functional-icon", "customized-icon", "adaptable-icon", "specialized-icon", "secure-icon", "easy-icon"]}
    }
  ) {
    edges {
      node {
        base
        childrenImageSharp {
          fluid(maxWidth: 80, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`