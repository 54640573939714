export const apps = [
    {
        app: "Digital Menu",
        title: {
            en: "Technology for your costumers",
            es: "Tecnología para tus clientes."
        },
        description: {
            en: "Cheaper than using printed menus. Unlimited dishes and drinks you can offer. Also helps reduce physical contact as a hygiene measure.",
            es: "Más barato que usar impresos. Muestra todos los platillos y bebidas que ofreces. También ayuda a reducir el contacto físico como medida de higiene."
        },
        align: "center",
        link: "/digital-menu/"
    },
    {
        app: "Manager Site",
        title: {
            en: "Custom your digital menu",
            es: "Edita el contenido del menú digital"
        },
        description: {
            en: "Always keep your digital menu updated. Add and edit items, descriptions, prices, etc. Find out which are your best-selling products through statistics.",
            es: "Mantén siempre actualizado el menú digital. Agrega y edita productos, descripciones, precios, etc. Descubre cuáles son los productos más vendidos a través de estadísticas."
        },
        align: "right",
        link: "/manager-site/"
    },
    {
        app: "Reservations",
        title: {
            en: "Well, now book your costumers",
            es: "Reserva ahora a tus clientes"
        },
        description: {
            en: "Reserve and manage your reservations. Different status for the different stages. Provide a way for your customers to confirm their reservations and accept the penalty policies.",
            es: "Reserva y adminitra tus reservacions durante la diferentes etapas del proceso. Provee a tus clientes una manera de confirmar sus reservas y aceptar las políticas de penalización"
        },
        align: "right",
        link: "/reservations/"
    },
    {
        app: "Survey Creator",
        title: {
            en: "What do you want to know?",
            es: "¿Qué deseas saber?"
        },
        description: {
            en: "Create and customize satisfaction surveys. With different types of questions to cover your needs.Manage your surveys to apply them in different places and times.",
            es: "Crea y personaliza encuestas de satisfacción. Con diferentes tipos de preguntas que cubren tus necesidades. Gestiona tus entrevistas para aplicarlo en diferentes lugares y momentos."
        },
        align: "right",
        link: "/survey-creator/"
    },
    {
        app: "Dotell",
        title: {
            en: "What do customers think about you",
            es: "Qué dicen tus clientes sobre ti"
        },
        description: {
            en: "You have already created your surveys, it is time to apply them to your customers. Easy and quick to respond.",
            es: "Una vez creadas tus encuestas, es tiempo de aplicarlas a tus clientes. Son fáciles y rápidas de responder."
        },
        align: "center",
        link: "/dotell/"
    },
    {
        app: "Dashboard",
        title: {
            en: "What gets measured gets improved",
            es: "Lo que se mide, se mejora"
        },
        description: {
            en: "The information obtained by the surveys is displayed in this tool. You will be able to know which areas are the best and which ones need improvement.",
            es: "Muestra la información obtenida por medio de las encuestas. Podrás saber cuáles áreas son las mejores y cuáles necesitan mejorar."
        },
        align: "right",
        link: "/dashboard/"
    },
    {
        app: "Taskme",
        title: {
            en: "Manage and follow the daily tasks of your team",
            es: "Gestiona y da seguimiento a las tareas diarias de tu equipo"
        },
        description: {
            en: "Plan the daily tasks and special tasks of the work team. Detect which tasks have been completed and which are overdue. Provide training using quizzes for the team.",
            es: "Haz la planeación diaria de todo tipo de tareas para el equipo. Detecta las tareas que han sido terminadas y cuáles están atrasadas. Proporcionar capacitación mediante cuestionarios para el equipo."
        },
        align: "center",
        link: "/taskme/"
    },
    {
        app: "Admin Site",
        title: {
            en: "All operational functions in one place",
            es: "Todas las funciones operativas en un solo lugar"
        },
        description: {
            en: "Manage your work team. Create and edit profiles, divisions, roles and grant individual permissions to each member of your team. Enter sales adjustments for correct accounting. *Included in all subscriptions.",
            es: "Gestiona al equipo. Crea y edita perfiles, divisiones, roles y otorga permisos individuales a cada miembro del equipo. Permite los ajustes de ventas para una contabilidad correcta. *Incluido en todas las suscripciones."
        },
        align: "right",
        link: "/admin-site/"
    },
    {
        app: "Appsinti",
        title: {
            en: "Finally, have all the control in the palm of your hand",
            es: "Finalmente, obtén el control en la palma de tu mano."
        },
        description: {
            en: "Connect through the business infrastructure to monitor the business facilities. Give personalized follow-up to clients who want to be contacted. See sales statistics over time.",
            es: "Conéctate a través de la infraestructura comercial para monitorear las instalaciones comerciales. Da seguimiento personalizado a los clientes que quieran ser contactados. Vea estadísticas de ventas a lo largo del tiempo."
        },
        align: "center",
        link: "/appsinti/"
    },
];