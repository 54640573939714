import React from 'react';
import { Container, Space } from '../../GlobalStyles.jsx';
import Highlight from '../../components/Highlight';
import { StaticImage } from "gatsby-plugin-image";
import styled from 'styled-components';
import { minDevice } from '../../Theme.js';

import useI18n from '../../i18n/useI18n'

const ImageContainer = styled.div`
    margin: 40px -15px 50px -15px;
    overflow: hidden;

    & div:nth-child(1){
        margin-left: -15%;
        margin-right: -15%;
    }

    @media ${minDevice.mobile} {
        margin: 0px;
        margin-top: 40px;
        margin-bottom: 20px;
        overflow: visible;

        & div:nth-child(1){
            margin: 0px;
        }
    }
`;

const Powerful = () => {

    const { translateObject } = useI18n();

    const highlightTitle = {
        en: 'Powerful',
        es: 'Herramientas'
    }

    const title = {
        en: 'tools with everything you need',
        es: 'con todo lo que que necesitas'
    }
    
    return (<>
        <Container center>
            <Space />
            <h2 data-sal="slide-up" data-sal-easing="ease-in-circ">
                <Highlight text={translateObject(highlightTitle)} />
                &nbsp;{translateObject(title)}
            </h2>

            <p className='max900'data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-delay='200'>
                { translateObject({
                    en:`
                        We have years of experience managing restaurants. 
                        With all the experience we bring you different tools to MANAGE, COORDINATE, 
                        know areas for IMPROVEMENT and ease the day-by-day tasks within your business, and not only for restaurants,
                        our tools are can adapt to different business with different needs
                    `,
                    es: `
                        Tenemos años de experiencia gestionando restaurantes.
                        Con base en nuestra experiencia ofrecemos diferentes herramientas para GESTIONAR, COORDINAR,
                        conocer áreas de MEJORA y facilitar el día a día dentro de tu negocio, y no es solo para restaurantes,
                        nuestras herramientas se pueden adaptar a diferentes negocios con diferentes necesidades
                    `
                })}
            </p>

            <ImageContainer data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-delay='300' data-sal-duration='400'>
                <StaticImage
                    src="../../images/products-home.png"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="Home Header"
                    style={{margin: '0px', marginLeft: '-50px'}}
                />
            </ImageContainer>
        </Container>            
    </>)
};

export default Powerful;