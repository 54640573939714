import React from 'react';
import { Container, Space } from '../../GlobalStyles.jsx';
import Highlight from '../../components/Highlight';
import { StaticImage } from "gatsby-plugin-image"

import useI18n from '../../i18n/useI18n';

const Hero = () => {

    const { translateObject } = useI18n();

    const highlightTitle = {
        en: 'Manage',
        es: 'Administra'
    }

    const title = {
        en: 'your business wherever you are',
        es: 'tu negocio dónde sea que estés'
    }
    
    return (<>
            <Container>
                <Space />
                <h1 
                    data-sal="slide-up"
                    data-sal-duration="800"
                    data-sal-easing="ease-in-circ"
                >
                    <Highlight text={translateObject(highlightTitle)} />
                    &nbsp;{translateObject(title)}
                </h1>
                <Space />
            </Container>
            <StaticImage
                src="../../images/home-hero.png"
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Home Header"
                style={{ width: '100%', marginBottom: '0' }}
            />
        </>)
};

export default Hero;
