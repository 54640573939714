import sal from 'sal.js'
import React, {useEffect} from 'react';
import styled from 'styled-components';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Contact from '../components/Contact';
import I18nProvider from '../i18n/provider';
import AlertServiceProvider from '@etiquette-ui/alert-dialog';

const Container = styled.div`
    height: 100%;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Layout = ({ children }) => {

    useEffect(() => {
        sal();
    }, []);

    return (
        <AlertServiceProvider>
            <I18nProvider>
                <Container>
                    <Navbar />
                    {children}
                    <Contact />
                    <Footer />
                </Container>
            </I18nProvider>
        </AlertServiceProvider>
    );
};

export default Layout;