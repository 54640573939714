export const info = [
    {
        title: {
            en:  "Functional",
            es: "Funcionales"
        },
        description: {
            en: "We have many apps that will facilitate your daily tasks and also those of your staff.",
            es: "Tenemos diversas aplicaciones para facilitar tus tareas del día a día para ti y tu equipo."
        }
    },
    {
        title: {
            en: "Customized",
            es: "A tu medida"
        },
        description: {
            en: "Our apps work better all together, but it is also possible to choose only a few and you will have great results.",
            es: "Nuestras applicaciones son mejor todas juntas, pero también es posible utilizar sólo las necesarias y obtener grandes resultados."
        }
    },
    {
        title: {
            en: "Adaptable",
            es: "Adaptables"
        },
        description: {
            en: "For small, medium and big businesses, our functionalities will adapt to your needs.",
            es: "Para pequeñas, medianas o grandes empresas, nuestras herramientas siempre se adaptarán a las necesidades"
        }
    },
    {
        title: {
            en: "Specialized",
            es: "Especializadas"
        },
        description: {
            en: "We know the different areas of a business very well, so our apps work on specific areas of productivity.",
            es: "Conocemos todas las áreas muy bien, así que nuestras aplicaciones trabajan con cada una de ellas."
        }
    },
    {
        title: {
            en: "Secure",
            es: "Seguras"
        },
        description: {
            en: "Easily control and manage the access and permissions granted to your staff.",
            es: "Gestiona facilmente y de forma segura el acceso y los permisos para tu equipo"
        }
    },
    {
        title: {
            en: "Easy To Use",
            es: "Fáciles de usar"
        },
        description: {
            en: "Big tasks, simple solutions. Our apps are designed to be very easy to use for the different users.",
            es: '"Tareas grandes, soluciones simples". Nuestras applicaciones son fáciles de usar para todo tipo de usuarios.'
        }
    },
];