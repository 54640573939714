import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Card } from '@etiquette-ui/misc';
import { PrimaryButton, SecondaryButton } from '@etiquette-ui/buttons';
import { PRIMARY } from '@etiquette-ui/colors';

import useI18n from '../../../../i18n/useI18n';

const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  height: 565px;

  margin-left: 10px;

  h1{
      color: ${PRIMARY};
      margin: 15px auto;
      padding: 0;
  }
  h3{
      margin: 30px 0 5px 0;
      padding: 0;
  }
  p{
    line-height: 2.5em;
    margin: 0;
    padding: 0;
    max-width: 270px;
  }
`;

const Image = styled.div`
  width: 64px;
`;

const Pricing = ({ id, img, title, description, price, button }) => {
  const data = useStaticQuery(PriceQuery);
  const dataimage = data.allFile.edges;

  const { translate, translateObject } = useI18n();

  const typeButton = button === 'primary' ? PrimaryButton : SecondaryButton;
  const Button = styled(typeButton)`
    width: 200px;
  `;

  return (
    <Container id={id}>
      <Image>
        <Img
          fluid={dataimage[img].node.childrenImageSharp[0].fluid}
          alt={dataimage[img].node.base}
        />
      </Image>
      <h3>{translateObject(title)}</h3>
      <p>{translateObject(description)} </p>
      <h1>${price}</h1>
      <Button>{translate('I Want This')}</Button>
    </Container >
  );
};
export default Pricing;

export const PriceQuery = graphql`
query Badges {
    allFile(
      filter: {extension: {regex: "/(png)/"}, 
        name: {in: [
          "pro-icon", 
          "medium-icon", 
          "basic-icon"
        ]}}
    ) {
      edges {
        node {
          base
          childrenImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;