import styled from "styled-components";
import { TEXT } from '@etiquette-ui/colors';
import { device, minDevice } from '../../../Theme';

const Card = styled.div`
    width: 100%;
    border-radius: 8px;
    cursor: pointer;
    overflow-y: hidden;

    border-bottom: solid white 1px;

    transition-property: background-color, border-radius, padding, color, margin;
    transition-duration: 0.5s;

    margin: ${({ click }) => (click ? '10px 0' : '0')};
    padding: 15px 0px 5px 0px;
    color: ${({ click }) => (click ? TEXT : '#fff')};
    background-color: ${({ click }) => (click ? '#F3FCFF' : 'transparent')};
    border-radius: ${({ click }) => (click ? '8px' : '0')};

    @media ${device.tablet} {
        padding: 16px;
    }
`;

const MainTitle = styled.h2`
    margin-top: 0px;
    margin-bottom: 2px;
`;

const Title = styled.p`
    font-weight: 600;
    color: ${({ click }) => (click ? TEXT : '#fff')};
    transition-property: margin-left;
    transition-duration: .5s;
    margin-left: ${({ click }) => (click ? '18px' : '0px')};;
    margin-top: 2px;
    margin-bottom: 10px;
`;

const Description = styled.p`
    color: ${TEXT};
    transition-property: height;
    transition-duration: .5s;
    padding: 0px 18px;
    margin: 0px;

    height: ${({dynamicHeight}) => !!dynamicHeight ? dynamicHeight : 0}px;

    .description-mask {
        height: fit-content;
        padding-bottom: 15px;
    }
`;

const Container = styled.div`
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const MainContainer = styled.div`
    position: relative;
`;

const MiddleScreenContainer = styled.div`
    max-width: 100%;
    flex: 0 0 100%;

    @media ${minDevice.mobileXL} {
        max-width: 58.333333%;
        flex: 0 0 58.333333%;
    }
`;

const whiteArrow = {
    svg: {
        width: '1rem',
        height: '1rem',
        marginRight: '16px'
    },
    line: {
        fill: 'none',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: '1.5px',
        stroke: 'white'
    }
};

const darkArrow = {
    svg: {
        width: '1rem',
        height: '1rem',
        marginRight: '16px'
    },
    line: {
        fill: 'none',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: '1.5px',
        stroke: TEXT
    }
};

const GridContainer = styled.div`
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media ${device.mobileXL} {
        grid-template-columns: 1fr;
    }
`;

const ImageContainer = styled.div`
    flex: 0 0 100%;
    max-width: 100%;

    position: relative;
    top: 0px;
    right: 0px;
    height: auto;
    max-height: 400px;
    overflow: hidden;

    @media ${minDevice.mobileXL} {
        height: 100%;
        max-height: 100%;
        position: absolute;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    }

    @media ${device.mobileXL} {
        div:nth-child(1){
            margin-top: -25%;
        }
    }
`;

export {
    Card,
    Title,
    MainTitle,
    Description,
    Container,
    MiddleScreenContainer,
    ImageContainer,
    MainContainer,
    whiteArrow,
    darkArrow,
    GridContainer
};