import React from 'react';
import { Container, Space } from '../../GlobalStyles.jsx';
import Highlight from '../../components/Highlight';
import { StaticImage } from "gatsby-plugin-image";
import styled from 'styled-components';
import { minDevice } from '../../Theme.js';

import useI18n from '../../i18n/useI18n'

const ImageContainer = styled.div`
    margin: 0px;
    padding: 0px;
    display: block;
    position: static;
    margin-top: 45px;
    margin-bottom: 64px;

    div:nth-child(1) {
        height: 240px;
        width: auto;
        
        @media ${minDevice.mobile} { 
            height: auto;
            width: 100%;
            max-height: 400px;
            margin-top: 80px;
            margin-bottom: 120px;
        }
    }
`;

const Title = styled.h2`
    margin-bottom: 30px;
    margin-top: 20px;
    @media ${minDevice.mobile} { 
        margin-top: 10px;
        margin-bottom: 40px;
    }
`;

const DifferentTask = () => {

    const { translateObject } = useI18n();
    
    const highlightTitle = {
        en: 'Different tasks',
        es: 'Las tareas diferentes'
    }

    const title = {
        en: 'require different tools',
        es: 'requieren herramientas diferentes'
    }

    return (<>
        <Container center>
            <Space />
            <Title data-sal="slide-up" data-sal-easing="ease-in-circ">
                <Highlight text={translateObject(highlightTitle)} /> {translateObject(title)}
            </Title>

            <p className='max900'data-sal="slide-up" data-sal-easing="ease-in-circ" data-sal-delay='200'>
                {translateObject({
                    en: `You don’t use a stone to erase a draw,
                    we have specialized tools that help improve workflows
                    and have better control of your business.`,
                    es: `"No usas una piedra para borrar un dibujo",
                    contamos con herramientas especializadas que ayudan a mejorar los flujos de trabajo
                    y tener un mejor control del negocio.`
                })}
                
            </p>
        </Container>
        <ImageContainer>
            <StaticImage
                src="../../images/home-banner.png"
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Home Header"
                style={{ width: '100%', margin: '0px' }}
            />
        </ImageContainer>
    </>)
};

export default DifferentTask;