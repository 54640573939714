import React from 'react';
import { Container, Space } from '../../../GlobalStyles';
import Highlight from '../../../components/Highlight';
import styled from 'styled-components';
import Carousel from './components/Carousel';
import { PRIMARY } from '@etiquette-ui/colors';
import { Link } from 'gatsby';
import { device } from '../../../Theme';

import useI18n from '../../../i18n/useI18n';

const Month = styled.p`
    font-size: 20px;
    font-weight: 600;
`;
const LinkContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 10px;
    align-items: center;
    justify-items: center;
    width: fit-content;
    margin: 0 auto;
    padding: 25px;

    @media ${device.mobileXL} {
        grid-template-columns: 100%;
        column-gap: 0px;
    }

    p{
        font-weight: 600;
    }
`;
const LearnLink = styled(Link)`
    width: 100%;
    color: ${PRIMARY};
    font-size: 16px;
    letter-spacing: 0px;
    font-weight: 500;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    @media ${device.tablet} {
        font-size: 14px;
    }
`;

const Pricing = () => {

    const { translateObject } = useI18n();

    const highlightTitle = {
        en: 'Pric',
        es: 'Precio'
    }

    const title = {
        en: 'ing',
        es: ''
    }
    
    return (
        <Container center dark>
            <Space />
            <h2> <Highlight text={translateObject(highlightTitle)} />{translateObject(title)} </h2>
            <Month>
                {translateObject({
                    en: 'Monthly',
                    es: 'Mensual'
                })}                
            </Month>
            <Carousel />
            <LinkContainer>
                <p>
                    {translateObject({
                        en: 'You also can customize your plan',
                        es: 'O también puedes personalizar tu plan'
                    })} 
                </p>
                <LearnLink>
                    {translateObject({
                        en: 'I Want To Learn More',
                        es: 'Quiero saber más'
                    })} 
                </LearnLink>
            </LinkContainer>
            <Space space='100px' />
        </Container >
    )
};

export default Pricing;