import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Img from 'gatsby-image';
import Icon from '@etiquette-ui/icons';
import { Container } from '../../../../GlobalStyles';
import {
  AppName,
  Title,
  AppTitle,
  Image,
  LinkContainer,
  LearnMore,
  Info,
  ImageContainer,
  ContainerFluid,
  MainDescription,
  Body
} from './sideInfoStyles';

import useI18n from '../../../../i18n/useI18n';

const Products = ({ title, info, app, align, link }) => {
  const data = useStaticQuery(ProductsQuery);
  const dataimage = data.allFile.edges;

  const {screen, icon} = findMediaFiles(dataimage, link);

  const formatedImage = icon.childrenImageSharp[0].fixed;
  const description = icon.base;

  const formatedScreen = screen.childrenImageSharp[0].fixed;
  const screenDescription = screen.base;

  const { translate, translateObject } = useI18n();

  return (
    <ContainerFluid>
      <MainDescription>
        <Container>
          <Body data-sal='slide-left' data-sal-easing="ease-out-cubic" data-sal-duration='800'>
            <AppName>
              <Image>
                <Img 
                  fixed={formatedImage} 
                  alt={description} 
                  style={{
                    width: '100%',
                    height: '100%'
                  }}
                  imgStyle={{
                    width: '190%',
                    height: '190%',
                    marginLeft: '-45%',
                    marginTop: '-32%',
                    objectFit: 'unset',
                  }}
                />
              </Image>
              <AppTitle>{translate(app)}</AppTitle>
            </AppName>

            <div>
              <Title>{translateObject(title)}</Title>
              <Info>
                {translateObject(info)}
              </Info>
            </div>

            <LinkContainer>
              <LearnMore to={link}>{translate('Learn More')}</LearnMore>
              <Icon icon='arrow-right' />
            </LinkContainer>
          </Body>
          
        </Container>
      </MainDescription>

      <ImageContainer className={`${align}`} data-sal="slide-right" data-sal-easing="ease-out-cubic" data-sal-delay='200' data-sal-duration='600'>
        <Img 
            style={{ 
              height: '100%',
              minWidth: '100%',
              
            }}
            imgStyle={{
              objectFit: `${!!align && align === 'center' ? 'none' : 'cover'}`,
              objectPosition: 'left top',
              width: `${!!align && align === 'center' ? 'auto' : '100%'}`, 
              height: `${!!align && align === 'center' ? '100%' : 'auto'}`,
            }}
            fixed={formatedScreen} 
            alt={screenDescription}
          />
      </ImageContainer>
    </ContainerFluid>
  );
};

export default Products;

const findMediaFiles = ( data, link) => {
  let appName = link.replaceAll('/', '');
  appName = appName.replaceAll('-', '');
  let screen, icon;

  data.forEach(item => {
    if(item.node.base.includes(appName)) {
      if(item.node.base.includes('screen')){
        screen = item.node;
        return;
      }
      icon = item.node;
      return;
    }
  });
  
  return {screen, icon};
}

export const ProductsQuery = graphql`
query MyQuery {
    allFile(
      filter: {
        extension: {regex: "/(png)/"}, 
        name: {in: [
            "digitalmenu-icon",
            "managersite-icon",
            "reservations-icon",
            "surveycreator-icon",
            "dotell-icon",
            "dashboard-icon",
            "taskme-icon",
            "adminsite-icon",
            "appsinti-icon",
            "digitalmenu-screen",
            "managersite-screen",
            "reservations-screen",
            "surveycreator-screen",
            "dotell-screen",
            "dashboard-screen",
            "taskme-screen",
            "adminsite-screen",
            "appsinti-screen"
        ]}}
    ) {
      edges {
        node {
          base
          childrenImageSharp {
            fixed(height: 700, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;