export const faqsData = [
    {
        title: {
            en:'My business is not a restaurant, can I use the tools?',
            es: 'Si mi negocio no es un restaurant, ¿puedo usar las herramientas?'
        },
        description: {
            en: 'Of course, our tools are focused on restaurants, however they have functionalities that adapt to different types of businesses, whether small, medium or large.',
            es: '[ES] Descripción'
        }
    },
    {
        title: {
            en:'What do I need to install the applications?',
            es: '¿Qué necesito para instalar las aplicaciones?'
        },
        description: {
            en: '[EN] Description',
            es: '[ES] Descripción'
        },
    },
    {
        title: {
            en:'What if I require only one tool?',
            es: '¿Qué pasa si sólo necesito una de las herrmientas?'
        },
        description: {
            en: '[EN] Description',
            es: '[ES] Descripción'
        },
    },
    {
        title: {
            en:'Do I need advanced knowledge in technology to use them?',
            es: '¿Necesito tener conocimientos avanzados en aplicaciones para usar las herramientas?'
        },
        description: {
            en: '[EN] Description',
            es: '[ES] Descripción'
        },
    },
    {
        title: {
            en:'How can I subscribe?',
            es: '¿Cómo me puedo subscribir?'
        },
        description: {
            en: '[EN] Description',
            es: '[ES] Descripción'
        },
    },
    {
        title: {
            en:'Do you offer technical support if I require it?',
            es: '¿Ofrecen soporte técnico en casa de lo necesite?'
        },
        description: {
            en: '[EN] Description',
            es: '[ES] Descripción'
        },
    },
    {
        title: {
            en:'What kind of protection is offered to have safe access?',
            es: '¿Qué tipo de protección ofrecen para tener un acceso seguro?'
        },
        description: {
            en: '[EN] Description',
            es: '[ES] Descripción'
        },
    },
    {
        title: {
            en:'Is there some kind of guarantee of use?',
            es: '¿Hay algún tipo de garantía de uso?'
        },
        description: {
            en: '[EN] Description',
            es: '[ES] Descripción'
        },
    },
    {
        title: {
            en:'Does this have connection with POS?',
            es: '¿Tienen conexión con algún Punto de Venta?'
        },
        description: {
            en: '[EN] Description',
            es: '[ES] Descripción'
        },
    },
];