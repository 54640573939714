import React, { useState, useEffect, useRef } from 'react';
import {
    Card,
    Title,
    Description,
    Container,
    darkArrow,
    whiteArrow
} from '../styles';
import Icon from '@etiquette-ui/icons';

const AnimateCard = ({ title, description }) => {
    const descriptionContainer = useRef(null);
    const [height, setHeight] = useState(0);
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    const arrow = click ? 'chevron-up' : 'chevron-down';
    const arrowColor = click ? darkArrow : whiteArrow;

    useEffect(()=>{
        setHeight(descriptionContainer.current.clientHeight);
    },[click]);

    return (
        <Card onClick={handleClick} click={click}>
            <Container>
                <Title click={click}>
                    {title}
                </Title>
                <Icon icon={arrow} styles={arrowColor} />
            </Container>

            <Description click={click} dynamicHeight={click ? height : 0 }>
                <div ref={descriptionContainer} className="description-mask">
                    {description}
                </div>
            </Description>
        </Card>
    );
};
export default AnimateCard;