import React from 'react';
import {
    Title,
    Description,
    Image
} from '../styles';

import useI18n from '../../../../i18n/useI18n'

const Item = ({ text, title, children }) => {

    const { translateObject } = useI18n();
    
    return (
        <div data-sal="slide-up" data-sal-easing="ease-out-cubic" data-sal-duration='800'>
            <Image>{children}</Image>
            <Title>
                {translateObject(title)}
            </Title>
            <Description>{translateObject(text)}</Description>
        </div>
    )
};

export default Item;