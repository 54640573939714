import styled from "styled-components";
import { device, minDevice } from '../../../Theme';

const Wrap = styled.div`
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
  @media ${minDevice.mobile} { 
    width: 90%;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const Row = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  @media ${device.mobile} { 
    padding-top: 10px;
    padding-bottom: 25px;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12%;

  @media ${device.mobile} { 
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 10px;
    grid-column-gap: 30px;
  }
  @media ${device.mobileXL} { 
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 30px;
    grid-column-gap: 0;
    justify-items: center;
  }
  @media ${minDevice.mobile} { 
    width: 90%;
    margin: 60px auto 8% auto;
  }
`;

const MainTitle = styled.h2`
  margin-bottom: 40px;
`;

const Title = styled.h4`
  font-size: 24px;
  margin: 0;
  margin-top: 15px;
  width: 100%;
  text-align: center;
  @media ${minDevice.mobile} { 
    text-align: left;
  }
`;
const Description = styled.p`
  margin-top: 3px;
  text-align: center;
  @media ${minDevice.mobile} { 
    text-align: left;
  }
`;

const Image = styled.div`
  width: 64px;
  height: 64px;
  margin: auto;
  @media ${minDevice.mobile} { 
    width: 80px;
    height: 80px;
    margin-left: 0px;
  }
`;

export {
  MainTitle,
  Title,
  Description,
  Image,
  Grid,
  Wrap,
  Row
}