export const dataprice = [
    {
        title: {
            en: 'Pro',
            es: 'Pro'
        },
        img: 2,
        description: {
            en: "Excepteur sint occaecat cupidatat Proident, sunt in culpa Qui officia in deserunt Mollitanim id est Sint laborum occaecat cupi",
            es: '[ES] Excepteur sint occaecat cupidatat Proident'
        },
        price: '000',
        button: 'primary',
        id: "slide-1"
    },
    {
        title: {
            en: 'Medium',
            es: 'Medio'
        },
        img: 1,
        description: {
            en: "Excepteur sint occaecat cupidatat Proident, sunt in culpa Qui officia in deserunt Mollitanim id est Sint laborum occaecat cupi",
            es: '[ES] Excepteur sint occaecat cupidatat Proident'
        },
        price: '000',
        button: 'secundary',
        id: "slide-2"
    },
    {
        title: {
            en: 'Basic',
            es: 'Básico'
        },
        img: 0,
        description: {
            en: "Excepteur sint occaecat cupidatat Proident, sunt in culpa Qui officia in deserunt Mollitanim id est Sint laborum occaecat cupi",
            es: '[ES] Excepteur sint occaecat cupidatat Proident'
        },
        price: '000',
        button: 'secundary',
        id: "slide-3"
    },
];