import React from 'react';
import { Container as GlobalContainer, Space } from '../../../GlobalStyles.jsx';
import AnimateCard from './components/AnimatedCard';
import { 
    MainTitle,
    MainContainer, 
    MiddleScreenContainer, 
    ImageContainer 
} from './styles';
import { StaticImage } from "gatsby-plugin-image";
import { faqsData } from './data';
import Highlight from '../../../components/Highlight.js';
import useI18n from '../../../i18n/useI18n';

const Faqs = () => {

    const { translateObject } = useI18n();

    const highlightTitle = {
        en: 'FAQ',
        es: 'Preguntas'
    }

    const title = {
        en: 's',
        es: ' frecuentes'
    }
    
    return (
        <MainContainer>
            <GlobalContainer>
                <MiddleScreenContainer>
                    <Space size='80' />
                    <MainTitle> <Highlight text={translateObject(highlightTitle)} />{translateObject(title)}</MainTitle>
                    <p>
                        {translateObject({
                            en: 'We help you to solve the most common and frequent questions of our customers.',
                            es: 'Te ayudamos a resolver las dudas más comunes y frecuentes de nuestros clientes.'
                        })}                        
                    </p>
                    <Space size='0' />

                    {faqsData.map(item => <AnimateCard
                        title={translateObject(item.title)}
                        description={translateObject(item.description)}
                    />
                    )}
                    <Space size='100' />
                </MiddleScreenContainer>
            </GlobalContainer>
            <ImageContainer>
                <StaticImage
                    src="../../../images/faqs.png"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="Home Header"
                    style={{ width: 'auto', height: '100%' }}
                />
            </ImageContainer>
            
        </MainContainer>
    )
};

export default Faqs;