import React from 'react';
import styled from 'styled-components';
import PriceInfo from './PriceInfo';
import { dataprice } from '../data';
import { device } from '../../../../Theme';
import { PRIMARY } from '@etiquette-ui/colors';

const Container = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;

  @media ${device.mobileXL} {
    width: 330px;
    margin: 0 auto;
    padding: 20px;
  }
  @media ${device.mobileS} {
    width: 280px;
    padding: 0px;
  }

& > a {
  display: none;
  width: 12px;
  height: 12px;
  background: white;
  text-decoration: none;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 50%;
  margin: 0 8px 0.5rem 8px;
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-color: black;
  
  @media ${device.mobile} {
      display: inline-flex;
    }
}
& > a:active {
  top: 1px;
  background: ${PRIMARY};
}
& > a:focus {
  background: ${PRIMARY};
}
& > a:hover {
  background: ${PRIMARY};
}
`;

const Slides = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 16px;
    justify-items: center;
    align-items: center;
    max-width: 1200px;
    height: 600px;
    padding: 20px;
    margin: 0 auto;
    
    overflow-x: auto;
    scroll-snap-type: x mandatory; 
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
    
    @media ${device.mobile} {
      display: flex;
      max-width: 1200px;
      height: 585px;
      padding: 0px 10px 0 0;
      margin: 0;
    }

    &::-webkit-scrollbar {
    width: 10px;
    height: 24px;
    }
    &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
    background: transparent;
    }
    & > div {
    scroll-snap-align: start;
    flex-shrink: 0;
    max-width: 280px;


    height: 550px;
    border-radius: 10px;
    transform-origin: center center;
    transform: scale(1);
    transition: transform 0.5s;
    
    @media ${device.mobile} {
      height: 530px;
    }
    }
`;

const Carousel = () => {

  return (
    <Container>
      <Slides>
        {dataprice.map((item, i) => (
          <PriceInfo
            title={item.title}
            img={item.img}
            description={item.description}
            price={item.price}
            button={item.button}
            id={`slide-${i}`}
          />
        ))
        }
      </Slides>

      <a className='nav' id="circle-0" href="#slide-0"></a>
      <a className='nav' id="circle-1" href="#slide-1"></a>
      <a className='nav' id="circle-2" href="#slide-2"></a>
    </Container>
  );
};

export default Carousel;
