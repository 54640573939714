import React from 'react';
import { Space } from '../../../GlobalStyles.jsx';
import SideInfo from './components/SideInfo';
import { apps } from "./data";

const Products = () => (
    <>
        <Space />
        {apps.map(app =>
            <>
                <SideInfo
                    app={app.app}
                    title={app.title}
                    info={app.description}
                    align={app.align}
                    link={app.link}
                />
                <Space size={80} />
            </>
        )}
    </>
);

export default Products;